import React, { useState, useEffect } from 'react';
import { ReservationService } from '../../../services';
import { IPlanDto, SeatReservationDto, IConcertDto } from '../../../clients/services';
import { ISeatDisplayInfo, IBlockDisplayInfo } from '../services/plan-info';
import { PlanFactory } from '../services/plan-factory';
import { RestrictionChecker } from '../services/restriction-checker';
import { Plan } from '../plan';
import { MainContent } from '../../../components/MainContent';
import { DateFormat } from 'cm-utils';
import { RootDiv } from '../styles';

const service = new ReservationService();
type PlanProps = {
    concertId?: number;
    planId?: number;
    devMode: boolean;
};

export const PlanView: React.FC<PlanProps> = (props) => {
    const [state, setState] = useState<
        | {
              concert: IConcertDto | null;
              plan: IPlanDto;
              blocks: {
                  blocks: IBlockDisplayInfo[];
                  possibilities?: ISeatDisplayInfo[][];
              };
              reservations: SeatReservationDto[];
          }
        | undefined
    >(undefined);

    const loadPlan = async () => {
        try {
            if (props.concertId) {
                const concertInfo = await service.getInfoForConcertAsync(props.concertId);
                const newPlanFactory = new PlanFactory(
                    new RestrictionChecker(concertInfo.plan?.restrictions),
                    concertInfo.reservations,
                    concertInfo.plan,
                );
                const blocks = newPlanFactory.getPlanForOwnReservations([], undefined);
                setState({
                    concert: concertInfo.concert,
                    blocks,
                    plan: concertInfo.plan,
                    reservations: concertInfo.reservations,
                });
            } else if (props.planId) {
                const plan = await service.getPlanAsync(props.planId);
                const newPlanFactory = new PlanFactory(new RestrictionChecker(plan.restrictions), [], plan);
                const blocks = newPlanFactory.getPlanForOwnReservations([], undefined);
                setState({
                    concert: null,
                    blocks,
                    plan: plan,
                    reservations: [],
                });
            }
        } catch (e) {
            alert(e);
        }
    };

    useEffect(() => {
        const loadData = async () => {
            await loadPlan();
        };
        loadData();
    }, []);

    return (
        <MainContent>
            {
                <RootDiv>
                    {state && (
                        <>
                            <h1>Planansicht</h1>
                            {state.concert && <h2>{state.concert.title}</h2>}
                            <h2 style={{ marginTop: '20px' }}>{state.plan.name}</h2>
                            {state.concert && (
                                <h3 style={{ marginBottom: '20px' }}>
                                    Datum: {state.concert.date?.toFormat(DateFormat.Long)}
                                </h3>
                            )}
                            {
                                <>
                                    <hr />
                                    <Plan
                                        plan={state.plan}
                                        blockInfos={state.blocks.blocks}
                                        nrOfDesiredSeats={1}
                                        ownReservations={[]}
                                        reservations={state.reservations}
                                        onClickSeat={(s: ISeatDisplayInfo) => {
                                            alert(`Seat Block: ${s.blockName} Row-${s.row}/Seat-${s.seat} `);
                                        }}
                                        devMode={props.devMode}
                                    />
                                </>
                            }
                        </>
                    )}
                    <hr />
                </RootDiv>
            }
        </MainContent>
    );
};
