import React, { useState, useEffect } from 'react';
import { ReservationService } from '../../../services';
import { IReservationInfoDto } from '../../../clients/services';
import { ISeatDisplayInfo, IBlockDisplayInfo } from '../services/plan-info';
import { SeatRestriction } from '../models/seat-restriction';
import { SeatState } from '../models/seat-state';
import { PlanFactory } from '../services/plan-factory';
import { RestrictionChecker } from '../services/restriction-checker';
import { Plan } from '../plan';
import { Alert, Button, LinearProgress, Paper } from '@mui/material';
import { RootDiv } from '../styles';
import { Link } from 'react-router-dom';
import { ISeatSelection } from '../services/seat-selection';

const service = new ReservationService();
type ReservationProps = {
    concertId: number;
    guid: string;
};

export const SimpleSeatView: React.FC<ReservationProps> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const [state, setState] = useState<{
        concertInfo: IReservationInfoDto;
        desired: ISeatSelection[];
        blocks: {
            blocks: IBlockDisplayInfo[];
            possibilities?: ISeatDisplayInfo[][];
            maxSeats?: number;
        };
    } | null>(null);

    const loadPlan = async () => {
        setIsLoading(true);
        try {
            const concertInfo = await service.getInfoForConcertAsync(props.concertId);
            const order = await service.getOrder(props.concertId, props.guid, false);
            const desired: ISeatSelection[] = order.reservations.map((r) => {
                const block = concertInfo.plan.blocks.find((b) => b.id === r.blockId);
                return {
                    seat: r.seat,
                    row: r.row,
                    blockId: r.blockId,
                    blockName: block?.name,
                    restriction: SeatRestriction.None,
                    state: SeatState.Desired,
                } as ISeatSelection;
            });
            const newPlanFactory = new PlanFactory(
                new RestrictionChecker(concertInfo.plan?.restrictions),
                concertInfo.reservations,
                concertInfo.plan,
            );
            const blocks = newPlanFactory.getPlanForOwnReservations(desired, desired.length);
            setState({
                concertInfo,
                desired,
                blocks,
            });
            setIsLoading(false);
        } catch {
            setError(true);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const loadData = async () => {
            await loadPlan();
        };
        loadData();
    }, []);

    return (
        <>
            {isLoading && <LinearProgress></LinearProgress>}
            {error && (
                <Alert severity="error">
                    Der Sitzplan konnte nicht geladen werden!
                    <br />{' '}
                    <Button variant="contained" onClick={loadPlan}>
                        Nochmals versuchen.
                    </Button>
                </Alert>
            )}
            {!isLoading && !error && (
                <>
                    <RootDiv style={{ backgroundColor: '#0d2f4e', fontSize: 11, minWidth: 1000, width: '100%' }}>
                        {state && (
                            <Plan
                                plan={state.concertInfo.plan}
                                blockInfos={state.blocks.blocks}
                                nrOfDesiredSeats={state.desired.length}
                                ownReservations={state.desired}
                                reservations={state.concertInfo.reservations}
                                onClickSeat={() => {
                                    /* do nothing */
                                }}
                                devMode={false}
                            />
                        )}
                    </RootDiv>
                    <Paper style={{ backgroundColor: '#F9F9F9', padding: 10, margin: 5 }}>
                        <Link
                            to={`/ticket/${props.concertId}/${props.guid}`}
                            color="inherit"
                            style={{ textDecoration: 'none' }}
                        >
                            <Button variant="contained">Ticket</Button>
                        </Link>
                        <Link
                            to={`/ticket-check/${props.concertId}/${props.guid}`}
                            color="inherit"
                            style={{ textDecoration: 'none' }}
                        >
                            <Button variant="contained">Ticketinfos</Button>
                        </Link>
                    </Paper>
                </>
            )}
        </>
    );
};
