import { RestrictionChecker } from './restriction-checker';
import { BlockDto, ISeatReservationDto, BlockDtoSeatCountDirection, IPlanDto } from '../../../clients/services';
import { IBlockDisplayInfo, IRowDisplayInfo, ISeatDisplayInfo, ISeatInfo } from './plan-info';
import { SeatState } from '../models/seat-state';
import { SeatRestriction } from '../models/seat-restriction';
import { BlockChecker } from './block-checker';

export class PlanFactory {
    private blocks: IBlockDisplayInfo[] = [];

    constructor(
        private restrictionInfo: RestrictionChecker,
        private reservations: ISeatReservationDto[],
        private plan: IPlanDto,
    ) {}

    getBlocks() {
        const blockInfos: IBlockDisplayInfo[] = [];
        for (const blockDto of this.plan.blocks) {
            const blockInfo = this.getBlock(blockDto);
            if (blockInfo) {
                blockInfos.push(blockInfo);
            }
        }
        return blockInfos;
    }

    getPlanForOwnReservations(
        ownReservations: ISeatInfo[],
        nrOfOwnReservations?: number,
    ): { blocks: IBlockDisplayInfo[]; possibilities?: ISeatDisplayInfo[][]; maxSeats?: number } {
        const result = this.getBlocks().map((b) => this.markAllFreeSpaces(b, ownReservations, nrOfOwnReservations));

        if (this.plan.restrictions?.seatsHaveToBeNextToEachOther) {
            const maxSeats = Math.max(...result.map((r) => r.maxSeats));
            const possibilities: ISeatDisplayInfo[][] = [];

            for (const blockPossibilities of result.map((b) => b.possibilities)) {
                for (const possibility of blockPossibilities) {
                    possibilities.push(possibility);
                }
            }

            return {
                blocks: result.map((b) => b.block),
                possibilities: possibilities,
                maxSeats: maxSeats,
            };
        }

        return {
            blocks: result.map((b) => b.block),
        };
    }

    private markAllFreeSpaces(
        blockInfo: IBlockDisplayInfo,
        ownReservations: ISeatInfo[],
        nrOfReservations?: number,
    ): { block: IBlockDisplayInfo; possibilities: ISeatDisplayInfo[][]; maxSeats: number } {
        if (this.restrictionInfo.blockIsBlocked(blockInfo.blockId)) {
            return {
                block: {
                    blockId: blockInfo.blockId,
                    rows: blockInfo.rows.map((r) => this.markRowBlocked(r)),
                    blockName: blockInfo.blockName,
                },
                possibilities: [],
                maxSeats: 0,
            };
        }

        const result = blockInfo.rows.map((r) =>
            this.markPossibleSeatsForReservation(r, ownReservations, nrOfReservations),
        );

        const possibilities: ISeatDisplayInfo[][] = [];
        for (const rowPossibilities of result.map((r) => r.possibilities)) {
            for (const possibility of rowPossibilities) {
                possibilities.push(possibility);
            }
        }
        const maxSeats = Math.max(...result.map((r) => r.maxSeats));
        return {
            block: {
                blockId: blockInfo.blockId,
                rows: result.map((r) => r.row),
                blockName: blockInfo.blockName,
            },
            possibilities: possibilities,
            maxSeats: maxSeats,
        };
    }

    private markPossibleSeatsForReservation(
        rowInfo: IRowDisplayInfo,
        ownReservations: ISeatInfo[],
        nrOfReservations?: number,
    ): { row: IRowDisplayInfo; possibilities: ISeatDisplayInfo[][]; maxSeats: number } {
        if (!ownReservations || ownReservations.length === 0) {
            return this.markRowWithPossibilities(rowInfo, nrOfReservations);
        } else {
            if (
                ownReservations.filter((r) => r.blockId === rowInfo.blockId && r.row === rowInfo.row).length === 0 ||
                this.restrictionInfo.rowIsBlocked(rowInfo.blockId, rowInfo.row)
            ) {
                return {
                    row: this.markRowBlocked(rowInfo),
                    possibilities: [],
                    maxSeats: rowInfo.seatInfos.filter(
                        (r) => r.state === SeatState.Free || r.state === SeatState.Desired,
                    ).length,
                };
            }
            return this.markRowWithOwnPossibilities(rowInfo, ownReservations, nrOfReservations);
        }
    }

    private ownReservationIsInPossiblity(ownReservations: ISeatInfo[], possibilities: ISeatDisplayInfo[][]) {
        return possibilities.filter((p) => this.everySeatIsInCollection(p, ownReservations));
    }

    private everySeatIsInCollection(collection: ISeatInfo[], seats: ISeatInfo[]) {
        if (collection.length < seats.length) {
            return false;
        }

        for (const seat of seats) {
            if (
                collection.filter((s) => s.blockId === seat.blockId && s.row === seat.row && s.seat === seat.seat)
                    .length === 0
            ) {
                return false;
            }
        }
        return true;
    }

    private seatIsInPossibilities(seat: ISeatDisplayInfo, possibilities: ISeatDisplayInfo[][]) {
        for (const possibility of possibilities) {
            if (
                possibility.filter((s) => seat.blockId === s.blockId && seat.row === s.row && seat.seat === s.seat)
                    .length > 0
            ) {
                return true;
            }
        }
        return false;
    }

    private markRowWithOwnPossibilities(
        rowInfo: IRowDisplayInfo,
        ownReservations: ISeatInfo[],
        nrOfReservations?: number,
    ): { row: IRowDisplayInfo; possibilities: ISeatDisplayInfo[][]; maxSeats: number } {
        const copiedSeats: ISeatDisplayInfo[] = [];
        const possibilities = this.getPossibilities(rowInfo, nrOfReservations);
        const possibilitiesWithOwnReservation = this.ownReservationIsInPossiblity(ownReservations, possibilities);

        for (const seat of rowInfo.seatInfos) {
            const seatIsDesired =
                ownReservations.filter((s) => s.blockId === seat.blockId && s.row === seat.row && s.seat === seat.seat)
                    .length > 0;
            if (seatIsDesired) {
                copiedSeats.push(this.markSeat(seat, SeatState.Desired, SeatRestriction.None));
            } else {
                const seatIsInPossibility = this.seatIsInPossibilities(seat, possibilitiesWithOwnReservation);
                if (seatIsInPossibility) {
                    copiedSeats.push(this.markSeat(seat, SeatState.Free, SeatRestriction.None));
                } else {
                    if (seat.state === SeatState.Free) {
                        copiedSeats.push(this.markSeat(seat, SeatState.Blocked, SeatRestriction.Wasted));
                    } else {
                        copiedSeats.push(seat);
                    }
                }
            }
        }
        return {
            row: {
                blockId: rowInfo.blockId,
                row: rowInfo.row,
                seatInfos: copiedSeats,
                blockName: rowInfo.blockName,
            },
            possibilities: possibilitiesWithOwnReservation,
            maxSeats: rowInfo.seatInfos.filter((r) => r.state === SeatState.Free || r.state === SeatState.Desired)
                .length,
        };
    }

    private markRowWithPossibilities(
        rowInfo: IRowDisplayInfo,
        nrOfReservations?: number,
    ): { row: IRowDisplayInfo; possibilities: ISeatDisplayInfo[][]; maxSeats: number } {
        const copiedSeats: ISeatDisplayInfo[] = [];
        const possibilities = this.getPossibilities(rowInfo, nrOfReservations);
        for (const seat of rowInfo.seatInfos) {
            const isInPossibilities =
                possibilities.filter((p) => p.filter((s) => s.seat === seat.seat).length > 0).length > 0;
            if (isInPossibilities) {
                copiedSeats.push(seat);
            } else {
                if (seat.state === SeatState.Free) {
                    const newSeat = this.markSeat(seat, SeatState.Blocked, SeatRestriction.Wasted);
                    copiedSeats.push(newSeat);
                } else {
                    copiedSeats.push(seat);
                }
            }
        }
        return {
            row: {
                blockId: rowInfo.blockId,
                row: rowInfo.row,
                seatInfos: copiedSeats,
                blockName: rowInfo.blockName,
            },
            possibilities: possibilities,
            maxSeats: rowInfo.seatInfos.filter((r) => r.state === SeatState.Free || r.state === SeatState.Desired)
                .length,
        };
    }

    private markRowBlocked(rowInfo: IRowDisplayInfo): IRowDisplayInfo {
        const copiedSeats: ISeatDisplayInfo[] = [];
        for (const seat of rowInfo.seatInfos) {
            if (seat.state === SeatState.Free) {
                copiedSeats.push(this.markSeat(seat, SeatState.Blocked, SeatRestriction.NotInSameRow));
            } else {
                copiedSeats.push(seat);
            }
        }
        return {
            blockId: rowInfo.blockId,
            row: rowInfo.row,
            seatInfos: copiedSeats,
            blockName: rowInfo.blockName,
        };
    }

    private getPossibilities(rowInfo: IRowDisplayInfo, nrOfReservations?: number): ISeatDisplayInfo[][] {
        if (this.restrictionInfo.isRestrictionless(rowInfo.blockId, rowInfo.row) || nrOfReservations === undefined) {
            return this.getRestrictionlessPossibilities(rowInfo, nrOfReservations);
        }

        const possibilityies: ISeatDisplayInfo[][] = [];
        const firstFreeSeat = this.firstFreeSeat(rowInfo.seatInfos);
        if (firstFreeSeat) {
            const lastFreeSeatNr: number = firstFreeSeat.seat + nrOfReservations - 1;
            const nextFreeSeats = rowInfo.seatInfos
                .filter(
                    (s) => (s.state === SeatState.Free || s.state === SeatState.Desired) && s.seat <= lastFreeSeatNr,
                )
                .map((s) => s);
            if (nextFreeSeats.length === nrOfReservations) {
                possibilityies.push(nextFreeSeats);
            }
        }
        const lastFreeSeat = this.lastFreeSeat(rowInfo.seatInfos);

        if (lastFreeSeat) {
            const firstFreeSeatNr: number = lastFreeSeat.seat - nrOfReservations + 1;
            const nextFreeSeats = rowInfo.seatInfos
                .filter(
                    (s) => (s.state === SeatState.Free || s.state === SeatState.Desired) && s.seat >= firstFreeSeatNr,
                )
                .map((s) => s);
            if (nextFreeSeats.length === nrOfReservations && firstFreeSeat?.seat !== firstFreeSeatNr) {
                possibilityies.push(nextFreeSeats);
            }
        }
        return possibilityies;
    }

    private getRestrictionlessPossibilities(rowInfo: IRowDisplayInfo, nrOfReservations?: number): ISeatDisplayInfo[][] {
        const possibilityies: ISeatDisplayInfo[][] = [];
        const freeSeats = rowInfo.seatInfos.filter((s) => s.state === SeatState.Free || s.state === SeatState.Desired);

        freeSeats.sort((s1, s2) => (s1.seat > s2.seat ? 1 : -1));
        if (nrOfReservations === undefined) {
            return freeSeats.map((s) => [s]);
        }

        for (const seat of freeSeats) {
            const possibility = [seat];
            let nr = 1;
            for (let i = seat.seat + 1; freeSeats.find((s) => s.seat === i) && nr < nrOfReservations; i++) {
                nr++;
                possibility.push(freeSeats.find((s) => s.seat === i)!);
            }
            if (possibility.length === nrOfReservations) {
                possibilityies.push(possibility);
            }
        }

        return possibilityies;
    }

    private firstFreeSeat(seats: ISeatDisplayInfo[]): ISeatDisplayInfo | undefined {
        const freeSeats = seats.filter((s) => s.state === SeatState.Free || s.state === SeatState.Desired);
        if (freeSeats.length === 0) {
            return undefined;
        }
        const minSeatNr = Math.min(...freeSeats.map((s) => s.seat));
        return freeSeats.find((s) => s.seat === minSeatNr);
    }

    private lastFreeSeat(seats: ISeatDisplayInfo[]): ISeatDisplayInfo | undefined {
        const freeSeats = seats.filter((s) => s.state === SeatState.Free || s.state === SeatState.Desired);
        if (freeSeats.length === 0) {
            return undefined;
        }
        const minSeatNr = Math.max(...freeSeats.map((s) => s.seat));
        return freeSeats.find((s) => s.seat === minSeatNr);
    }

    private markSeat(seatInfo: ISeatDisplayInfo, state: SeatState, restriction: SeatRestriction): ISeatDisplayInfo {
        return {
            blockId: seatInfo.blockId,
            row: seatInfo.row,
            seat: seatInfo.seat,
            state: state,
            restriction: restriction,
            blockName: seatInfo.blockName,
        };
    }

    private getBlock(blockDto: BlockDto): IBlockDisplayInfo | undefined {
        if (!blockDto) {
            return undefined;
        }
        const newBlockInfo = {
            blockId: blockDto.id,
            rows: this.createRows(blockDto),
        } as IBlockDisplayInfo;
        this.blocks.push(newBlockInfo);
        return newBlockInfo;
    }

    private createRows(block: BlockDto) {
        const rows: IRowDisplayInfo[] = [];
        const blockChecker = new BlockChecker(block);
        for (let rowNr = blockChecker.startRowCountAt(); rowNr <= blockChecker.endRowCountAt(); rowNr++) {
            if (!blockChecker.isSkipped(rowNr)) {
                rows.push(this.createRow(blockChecker, block, rowNr));
            }
        }
        return rows;
    }

    private createRow(blockChecker: BlockChecker, block: BlockDto, rowNr: number): IRowDisplayInfo {
        const seats: ISeatDisplayInfo[] = [];
        if (block.seatCountDirection === BlockDtoSeatCountDirection.LeftToRight) {
            for (
                let seatNr = blockChecker.startSeatCountAt(rowNr);
                seatNr <= blockChecker.endSeatCountAt(rowNr);
                seatNr++
            ) {
                if (!blockChecker.seatIsRemoved(rowNr, seatNr)) {
                    seats.push(this.createSeat(block, rowNr, seatNr));
                }
            }
        } else {
            for (
                let seatNr = blockChecker.endSeatCountAt(rowNr);
                seatNr >= blockChecker.startSeatCountAt(rowNr);
                seatNr--
            ) {
                if (!blockChecker.seatIsRemoved(rowNr, seatNr)) {
                    seats.push(this.createSeat(block, rowNr, seatNr));
                }
            }
        }
        return {
            seatInfos: seats,
            row: rowNr,
            blockId: block.id,
            blockName: block.name ?? '',
        };
    }

    private seatIsGenerallyBlocked(block: BlockDto, rowNr: number, seatNr: number): boolean {
        if (!block) {
            return true;
        }

        if (block.blockAllSeats) {
            return true;
        }

        const rowSetting = block.rowSettings?.find((r) => r.row === rowNr);
        if (rowSetting) {
            if (rowSetting.blockRow) {
                return true;
            }

            if (rowSetting.blockSeats) {
                const seat = rowSetting.blockSeats.find((s) => s === seatNr);
                if (seat) {
                    return true;
                }
            }
        }

        return false;
    }

    private createSeat(block: BlockDto, rowNr: number, seatNr: number): ISeatDisplayInfo {
        const existingReservation = this.reservations.find(
            (r) => r.blockId === block.id && r.row === rowNr && r.seat === seatNr,
        );
        if (existingReservation) {
            return {
                blockId: block.id,
                row: rowNr,
                seat: seatNr,
                state: SeatState.Reserved,
                restriction: SeatRestriction.Reserved,
                blockName: block.name ?? '',
            };
        }

        if (this.seatIsGenerallyBlocked(block, rowNr, seatNr)) {
            return {
                blockId: block.id,
                row: rowNr,
                seat: seatNr,
                state: SeatState.Blocked,
                restriction: SeatRestriction.None,
                blockName: block.name ?? '',
            };
        }

        if (!this.restrictionInfo.hasRestrictions()) {
            return {
                blockId: block.id,
                row: rowNr,
                seat: seatNr,
                state: SeatState.Free,
                restriction: SeatRestriction.None,
                blockName: block.name ?? '',
            };
        }

        const restriction = this.restrictionInfo.getExplicitRestriction({
            blockId: block.id,
            row: rowNr,
            seat: seatNr,
        });
        if (restriction !== SeatRestriction.None) {
            return {
                blockId: block.id,
                row: rowNr,
                seat: seatNr,
                state: SeatState.Blocked,
                restriction: restriction,
                blockName: block.name,
            } as ISeatDisplayInfo;
        }

        if (this.restrictionInfo.isBlocked(this.reservations, block.id, rowNr, seatNr)) {
            return {
                blockId: block.id,
                row: rowNr,
                seat: seatNr,
                state: SeatState.Blocked,
                restriction: SeatRestriction.NextToReserved,
                blockName: block.name,
            } as ISeatDisplayInfo;
        }

        return {
            blockId: block.id,
            row: rowNr,
            seat: seatNr,
            state: SeatState.Free,
            restriction: SeatRestriction.None,
            blockName: block.name,
        } as ISeatDisplayInfo;
    }
}
