export interface AlbumData {
    artist: string;
    title: string;
}

export interface PlaylistData {
    year: number;
    month?: number;
    playlist: AlbumData[];
    image?: string;
}

export const playlists: PlaylistData[] = [
    {
        year: 2024,
        month: 4,
        image: '/images/SierraFerrell_TrailOfFlowers.jpg',
        playlist: [
            { artist: 'Sierra Ferrell', title: 'Trail Of Flowers' },
            { artist: 'Abe Mac', title: 'Freight Train Heart' },
            { artist: 'Suzy Bogguss', title: 'Prayin’ For Sunshine' },
            { artist: 'Wilder Blue', title: 'Super Natural' },
            { artist: 'Carolyn Sills Combo', title: 'On The Draw' },
            { artist: 'Margo Cilker', title: 'Valley Of Heart’s Delight' },
            { artist: 'Cody Johnson', title: 'Leather' },
            { artist: 'Lindsay Lou', title: 'Queen Of Time' },
            { artist: 'Jaime Wyatt', title: 'Feel Good' },
            { artist: 'Ashley Campbell & Thor Jensen', title: 'Turtle Cottage' },
            { artist: 'Rhiannon Giddens', title: 'You’re The One' },
            { artist: 'Charles Wesley Godwin', title: 'Family Ties' },
            { artist: 'Ray Scott', title: 'Billboards And Brake Lights' },
            { artist: 'Brit Taylor', title: 'Kentucky Bluegrassed' },
            { artist: 'Ellis Bullard', title: 'Honky Tonk Ain’t Noise Pollution' },
            { artist: 'Sentimental Family Band', title: 'Sweethearts Only' },
            { artist: 'Pat Reedy', title: 'Make It Back Home' },
            { artist: 'Kimmi Bitter', title: 'Old School' },
            { artist: 'Cody Jinks', title: 'Change The Game' },
            { artist: 'Gabe Lee', title: 'Drink The River' },
        ],
    },
    {
        year: 2024,
        month: 3,
        image: '/images/SuzyBogguss-PrayinForSunshine.jpg',
        playlist: [
            { artist: 'Suzy Bogguss', title: 'Prayin’ For Sunshine' },
            { artist: 'Jaime Wyatt', title: 'Feel Good' },
            { artist: 'Rhiannon Giddens', title: 'You’re The One' },
            { artist: 'Abe Mac', title: 'Freight Train Heart' },
            { artist: 'Wilder Blue', title: 'Super Natural' },
            { artist: 'Carolyn Sills Combo', title: 'On The Draw' },
            { artist: 'Margo Cilker', title: 'Valley Of Heart’s Delight' },
            { artist: 'Charles Wesley Godwin', title: 'Family Ties' },
            { artist: 'Lindsay Lou', title: 'Queen Of Time' },
            { artist: 'Cody Johnson', title: 'Leather' },
            { artist: 'Ray Scott', title: 'Billboards And Brake Lights' },
            { artist: 'Ashley Campbell & Thor Jensen', title: 'Turtle Cottage' },
            { artist: 'Gabe Lee', title: 'Drink The River' },
            { artist: 'Robert Henry & The Repeaters', title: 'The Way It Usually Goes' },
            { artist: 'Molly Tuttle & Golden Highway', title: 'City Of Gold' },
            { artist: 'Brit Taylor', title: 'Kentucky Bluegrassed' },
            { artist: 'Ellis Bullard', title: 'Honky Tonk Ain’t Noise Pollution' },
            { artist: 'Kruger Brothers & Kontras Quartet', title: 'Moonshine Sonata' },
            { artist: 'Morgan Lee Powers', title: 'How Naive' },
            { artist: 'Emily Ann Roberts', title: 'Can’t Hide Country' },
        ],
    },
    {
        year: 2024,
        month: 2,
        image: '/images/GabeLee-DrinkTheRiver.jpg',
        playlist: [
            { artist: 'Gabe Lee', title: 'Drink The River' },
            { artist: 'Jaime Wyatt', title: 'Feel Good' },
            { artist: 'Rhiannon Giddens', title: 'You’re The One' },
            { artist: 'Suzy Bogguss', title: 'Prayin’ For Sunshine' },
            { artist: 'Charles Wesley Godwin', title: 'Family Ties' },
            { artist: 'Carolyn Sills Combo', title: 'On The Draw' },
            { artist: 'Margo Cilker', title: 'Valley Of Heart’s Delight' },
            { artist: 'Wilder Blue', title: 'Super Natural' },
            { artist: 'Abe Mac', title: 'Freight Train Heart' },
            { artist: 'Lindsay Lou', title: 'Queen Of Time' },
            { artist: 'Ray Scott', title: 'Billboards And Brake Lights' },
            { artist: 'Kruger Brothers & Kontras Quartet', title: 'Moonshine Sonata' },
            { artist: 'Molly Tuttle & Golden Highway', title: 'City Of Gold' },
            { artist: 'Cody Johnson', title: 'Leather' },
            { artist: 'Ashley Campbell & Thor Jensen', title: 'Turtle Cottage' },
            { artist: 'Robert Henry & The Repeaters', title: 'The Way It Usually Goes' },
            { artist: 'Brennen Leigh', title: 'I Ain’t Through Honky Tonkin’ Yet' },
            { artist: 'Brent Cobb', title: 'Southern Star' },
            { artist: 'Morgan Lee Powers', title: 'How Naive' },
            { artist: 'Jim Kweskin : Duets With My Friends', title: 'Never Too Late' },
        ],
    },
    {
        year: 2024,
        month: 1,
        image: '/images/RhiannonGiddens-YoureTheOne.jpg',
        playlist: [
            { artist: 'Rhiannon Giddens', title: 'You’re The One' },
            { artist: 'Charles Wesley Godwin', title: 'Family Ties' },
            { artist: 'Gabe Lee', title: 'Drink The River' },
            { artist: 'Jaime Wyatt', title: 'Feel Good' },
            { artist: 'Suzy Bogguss', title: 'Prayin’ For Sunshine' },
            { artist: 'Kruger Brothers & Kontras Quartet', title: 'Moonshine Sonata' },
            { artist: 'Carolyn Sills Combo', title: 'On The Draw' },
            { artist: 'Margo Cilker', title: 'Valley Of Heart’s Delight' },
            { artist: 'Brent Cobb', title: 'Southern Star' },
            { artist: 'Wilder Blue', title: 'Super Natural' },
            { artist: 'Molly Tuttle & Golden Highway', title: 'City Of Gold' },
            { artist: 'Lindsay Lou', title: 'Queen Of Time' },
            { artist: 'Brennen Leigh', title: 'I Ain’t Through Honky Tonkin’ Yet' },
            { artist: 'Ray Scott', title: 'Billboards And Brake Lights' },
            { artist: 'Vince Gill & Paul Franklin', title: 'Sweet Memories' },
            { artist: 'Watson Twins', title: 'Holler' },
            { artist: 'Cody Johnson', title: 'Leather' },
            { artist: 'Dale Watson', title: 'Starvation Box' },
            { artist: 'Robert Henry & The Repeaters', title: 'The Way It Usually Goes' },
            { artist: 'Malpass Brothers', title: 'Lonely Street' },
        ],
    },
    {
        year: 2023,
        playlist: [
            { artist: 'Rhiannon Giddens', title: 'You’re The One' },
            { artist: 'Vince Gill & Paul Franklin', title: 'Sweet Memories' },
            { artist: 'Charley Wesley Godwin', title: 'Family Ties' },
            { artist: 'Jaimee Harris', title: 'Boomerang Town' },
            { artist: 'Kruger Brothers & Kontras Quartet', title: 'Moonshine Sonata' },
            { artist: 'Gabe Lee', title: 'Drink The River' },
            { artist: 'Brennen Leigh', title: 'Ain’t Through Honky Tonkin’ Yet' },
            { artist: 'Mary Elizabeth Long', title: 'Mary Elizabeth Long' },
            { artist: 'Malpass Brothers', title: 'Lonely Street' },
            { artist: 'Marty Stuart & The Fabulous Superlatives', title: 'Altitude' },
        ],
    },
    {
        year: 2023,
        month: 12,
        image: '/images/CharlesWesleyGodwin_FamilyTies.jpg',
        playlist: [
            { artist: 'Charles Wesley Godwin', title: 'Family Ties' },
            { artist: 'Rhiannon Giddens', title: 'You’re The One' },
            { artist: 'Kruger Brothers & Kontras Quartet', title: 'Moonshine Sonata' },
            { artist: 'Brennen Leigh', title: 'I Ain’t Through Honky Tonkin’ Yet' },
            { artist: 'Gabe Lee', title: 'Drink The River' },
            { artist: 'Molly Tuttle & Golden Highway', title: 'City Of Gold' },
            { artist: 'Vince Gill & Paul Franklin', title: 'Sweet Memories' },
            { artist: 'Charley Crockett', title: 'Live From The Ryman Auditorium' },
            { artist: 'Margo Cilker', title: 'Valley Of Heart’s Delight' },
            { artist: 'Watson Twins', title: 'Holler' },
            { artist: 'Brent Cobb', title: 'Southern Star' },
            { artist: 'Carolyn Sills Combo', title: 'On The Draw' },
            { artist: 'Jaime Wyatt', title: 'Feel Good' },
            { artist: 'Dale Watson', title: 'Starvation Box' },
            { artist: 'Wilder Blue', title: 'Super Natural' },
            { artist: 'Malpass Brothers', title: 'Lonely Street' },
            { artist: 'Lukas Nelson + POTR', title: 'Sticks And Stones' },
            { artist: 'Lindsay Lou', title: 'Queen Of Time' },
            { artist: 'Suzy Bogguss', title: 'Prayin’ For Sunshine' },
            { artist: 'Rodney Crowell', title: 'The Chicago Sessions' },
        ],
    },
    {
        year: 2023,
        month: 11,
        image: '/images/KrugerBrothersKontrasQuartetMoonshineSonata.jpg',
        playlist: [
            { artist: 'Kruger Brothers & Kontras Quartet', title: 'Moonshine Sonata' },
            { artist: 'Vince Gill & Paul Franklin', title: 'Sweet Memories' },
            { artist: 'Brennen Leigh', title: 'I Ain’t Through Honky Tonkin’ Yet' },
            { artist: 'Molly Tuttle & Golden Highway', title: 'City Of Gold' },
            { artist: 'Rhiannon Giddens', title: 'You’re The One' },
            { artist: 'Gabe Lee', title: 'Drink The River' },
            { artist: 'Charley Crockett', title: 'Live From The Ryman Auditorium' },
            { artist: 'Malpass Brothers', title: 'Lonely Street' },
            { artist: 'Watson Twins', title: 'Holler' },
            { artist: 'Dale Watson', title: 'Starvation Box' },
            { artist: 'Lukas Nelson + POTR', title: 'Sticks And Stones' },
            { artist: 'Margo Cilker', title: 'Valley Of Heart’s Delight' },
            { artist: 'Charles Wesley Godwin', title: 'Family Ties' },
            { artist: 'Rodney Crowell', title: 'The Chicago Sessions' },
            { artist: 'Brent Cobb', title: 'Southern Star' },
            { artist: 'Erin Enderlin', title: 'Barroom Mirrors' },
            { artist: 'Jaimee Harris', title: 'Boomerang Town' },
            { artist: 'Willie Nelson', title: 'Bluegrass' },
            { artist: 'Mary Elizabeth Long', title: 'Mary Elizabeth Long' },
            { artist: 'Carolyn Sills Combo', title: 'On The Draw' },
        ],
    },
    {
        year: 2023,
        month: 10,
        image: '/images/CharleyCrockett-Live.jpg',
        playlist: [
            { artist: 'Charley Crockett', title: 'Live From The Ryman Auditorium' },
            { artist: 'Vince Gill & Paul Franklin', title: 'Sweet Memories' },
            { artist: 'Malpass Brothers', title: 'Lonely Street' },
            { artist: 'Brennen Leigh', title: 'I Ain’t Through Honky Tonkin’ Yet' },
            { artist: 'Molly Tuttle & Golden Highway', title: 'City Of Gold' },
            { artist: 'Rhiannon Giddens', title: 'You’re The One' },
            { artist: 'Gabe Lee', title: 'Drink The River' },
            { artist: 'Lukas Nelson + POTR', title: 'Sticks And Stones' },
            { artist: 'Rodney Crowell', title: 'The Chicago Sessions' },
            { artist: 'Watson Twins', title: 'Holler' },
            { artist: 'Dale Watson', title: 'Starvation Box' },
            { artist: 'Jaimee Harris', title: 'Boomerang Town' },
            { artist: 'Erin Enderlin', title: 'Barroom Mirrors' },
            { artist: 'Mary Elizabeth Long', title: 'Mary Elizabeth Long' },
            { artist: 'Margo Cilker', title: 'Valley Of Heart’s Delight' },
            { artist: 'Jason Isbell & 400 Unit', title: 'Weathervanes' },
            { artist: 'Brent Cobb', title: 'Southern Star' },
            { artist: 'Marty Stuart & His Fabulous Superlatives', title: 'Altitude' },
            { artist: 'Joe Stamm Band', title: 'Wild Man' },
            { artist: 'Willie Nelson', title: 'Bluegrass' },
        ],
    },
    {
        year: 2023,
        month: 9,
        image: '/images/JaimeeHarris-BoomerangTown.jpg',
        playlist: [
            { artist: 'Jaimee Harris', title: 'Boomerang Town' },
            { artist: 'Malpass Brothers', title: 'Lonely Street' },
            { artist: 'Vince Gill & Paul Franklin', title: 'Sweet Memories' },
            { artist: 'Rodney Crowell', title: 'The Chicago Sessions' },
            { artist: 'Brennen Leigh', title: 'I Ain’t Through Honky Tonkin’ Yet' },
            { artist: 'Molly Tuttle & Golden Highway', title: 'City Of Gold' },
            { artist: 'Gabe Lee', title: 'Drink The River' },
            { artist: 'Erin Enderlin', title: 'Barroom Mirrors' },
            { artist: 'Mary Elizabeth Long', title: 'Mary Elizabeth Long' },
            { artist: 'Rhiannon Giddens', title: 'You’re The One' },
            { artist: 'Lukas Nelson + POTR', title: 'Sticks And Stones' },
            { artist: 'Jason Isbell & 400 Unit', title: 'Weathervanes' },
            { artist: 'Dale Watson', title: 'Starvation Box' },
            { artist: 'Watson Twins', title: 'Holler' },
            { artist: 'Brit Taylor', title: 'Kentucky Blue' },
            { artist: 'Brandy Clark', title: 'Brandy Clark' },
            { artist: 'Marty Stuart & His Fabulous Superlatives', title: 'Altitude' },
            { artist: 'Whitney Rose', title: 'Rosie' },
            { artist: 'Jake Worthington', title: 'Jake Worthington' },
            { artist: 'Turnpike Troubadours', title: 'A Cat In The Rain' },
        ],
    },
    {
        year: 2023,
        month: 8,
        image: '/images/JasonIsbell&400Unit-Weathervanes.jpg',
        playlist: [
            { artist: 'Jason Isbell & 400 Unit', title: 'Weathervanes' },
            { artist: 'Jaimee Harris', title: 'Boomerang Town' },
            { artist: 'Malpass Brothers', title: 'Lonely Street' },
            { artist: 'Brit Taylor', title: 'Kentucky Blue' },
            { artist: 'Rodney Crowell', title: 'The Chicago Sessions' },
            { artist: 'Mary Elizabeth Long', title: 'Mary Elizabeth Long	' },
            { artist: 'Brennen Leigh', title: 'I Ain’t Through Honky Tonkin’ Yet' },
            { artist: 'Erin Enderlin', title: 'Barroom Mirrors' },
            { artist: 'Molly Tuttle & Golden Highway', title: 'City Of Gold' },
            { artist: 'Brandy Clark', title: 'Brandy Clark' },
            { artist: 'Lukas Nelson + POTR', title: 'Sticks And Stones' },
            { artist: 'Summer Dean', title: 'The Biggest Life' },
            { artist: 'Dale Watson', title: 'Starvation Box' },
            { artist: 'Gabe Lee', title: 'Drink The River' },
            { artist: 'Marty Stuart & His Fabulous Superlatives', title: 'Altitude' },
            { artist: 'Jake Worthington', title: 'Jake Worthington' },
            { artist: 'Bri Bagwell', title: 'Corazon Y Cabeza' },
            { artist: 'The Shootouts', title: 'Stampede' },
            { artist: 'Eliza Gilkyson', title: 'Home' },
            { artist: 'Whitney Rose', title: 'Rosie' },
        ],
    },
    {
        year: 2023,
        month: 7,
        image: '/images/MaryElizabethLong-MaryElizabethLong.jpg',
        playlist: [
            { artist: 'Mary Elizabeth Long', title: 'Mary Elizabeth Long' },
            { artist: 'Jaimee Harris', title: 'Boomerang Town' },
            { artist: 'Jason Isbell & 400 Unit', title: 'Weathervanes' },
            { artist: 'Marty Stuart & His Fabulous Superlatives', title: 'Altitude' },
            { artist: 'Brit Taylor', title: 'Kentucky Blue' },
            { artist: 'Rodney Crowell', title: 'The Chicago Sessions' },
            { artist: 'Brandy Clark', title: 'Brandy Clark' },
            { artist: 'Jake Worthington', title: 'Jake Worthington' },
            { artist: 'Brennen Leigh', title: 'I Ain’t Through Honky Tonkin’ Yet' },
            { artist: 'Bri Bagwell', title: 'Corazon Y Cabeza' },
            { artist: 'Malpass Brothers', title: 'Lonely Street' },
            { artist: 'Erin Enderlin', title: 'Barroom Mirrors' },
            { artist: 'Ray Scott', title: 'Wrong Songs' },
            { artist: 'Tanya Tucker', title: 'Sweet Western Sound' },
            { artist: 'Megan Moroney', title: 'Lucky' },
            { artist: 'The Shootouts', title: 'Stampede' },
            { artist: 'Melonie Cannon', title: 'A Tribute To Vern Gosdin' },
            { artist: 'Summer Dean', title: 'The Biggest Life' },
            { artist: 'Dale Watson', title: 'Starvation Box' },
            { artist: 'Casey Prestwood', title: 'Where I’m Going Is Where I’ve Always Been' },
        ],
    },
    {
        year: 2023,
        month: 6,
        image: '/images/MartyStuartAndHisFabulousSuperlatives-Altitude.jpg',
        playlist: [
            { artist: 'Marty Stuart & His Fabulous Superlatives', title: 'Altitude' },
            { artist: 'Jake Worthington', title: 'Jake Worthington' },
            { artist: 'Mary Elizabeth Long', title: 'Mary Elizabeth Long' },
            { artist: 'Jaimee Harris', title: 'Boomerang Town' },
            { artist: 'Brit Taylor', title: 'Kentucky Blue' },
            { artist: 'Bri Bagwell', title: 'Corazon Y Cabeza' },
            { artist: 'Brandy Clark', title: 'Brandy Clark' },
            { artist: 'Megan Moroney', title: 'Lucky' },
            { artist: 'Rodney Crowell', title: 'The Chicago Sessions' },
            { artist: 'The Shootouts', title: 'Stampede' },
            { artist: 'Willie Nelson', title: 'I Don’t Know A Thing About Love' },
            { artist: 'Stam Platts & The Plainsmen', title: 'West Side' },
            { artist: 'Ray Scott', title: 'Wrong Songs' },
            { artist: 'Robbie Fulks', title: 'Bluegrass Vacation' },
            { artist: 'Band Of Heathens', title: 'Simple Things' },
            { artist: 'Tanya Tucker', title: 'Sweet Western Sound' },
            { artist: 'Dallas Moore', title: 'No God In Juarez' },
            { artist: 'Nickel Creek', title: 'Celebrants' },
            { artist: 'Melonie Cannon', title: 'A Tribute To Vern Gosdin' },
            { artist: 'Casey Prestwood', title: 'Where I’m Going Is Where I’ve Always Been' },
        ],
    },
    {
        year: 2023,
        month: 5,
        image: '/images/JakeWorthington-JakeWorthington.jpg',
        playlist: [
            { artist: 'Jake Worthington', title: 'Jake Worthington' },
            { artist: 'Mary Elizabeth Long', title: 'Mary Elizabeth Long' },
            { artist: 'The Shootouts', title: 'Stampede' },
            { artist: 'Bri Bagwell', title: 'Corazon Y Cabeza' },
            { artist: 'Jaimee Harris', title: 'Boomerang Town' },
            { artist: 'Willie Nelson', title: 'I Don’t Know A Thing About Love' },
            { artist: 'Brit Taylor', title: 'Kentucky Blue' },
            { artist: 'Band Of Heathens', title: 'Simple Things' },
            { artist: 'Megan Moroney', title: 'Lucky' },
            { artist: 'Stam Platts & The Plainsmen', title: 'West Side' },
            { artist: 'Rob Ickes & Trey Hensley', title: 'Living In A Song' },
            { artist: 'Nickel Creek', title: 'Celebrants' },
            { artist: 'Slaid Cleaves', title: 'Together Through The Dark' },
            { artist: 'Gibson Brothers', title: 'Darkest Hour' },
            { artist: 'Robbie Fulks', title: 'Bluegrass Vacation' },
            { artist: 'Ray Scott', title: 'Wrong Songs' },
            { artist: 'Bella White', title: 'Among Other Things' },
            { artist: 'Dallas Moore', title: 'No God In Juarez' },
            { artist: 'Lynn Crossett', title: 'In A Company Of A Song' },
            { artist: 'Amanda Fields', title: 'What, When And Without' },
        ],
    },
    {
        year: 2023,
        month: 1,
        image: '/images/AmandaShires_TakeItLikeAMan.jpg',
        playlist: [
            { artist: 'Amanda Shires', title: 'Take It Like A Man' },
            { artist: 'Melissa Carper', title: 'Ramblin‘ Soul' },
            { artist: 'American Aquarium', title: 'Chicamacomico' },
            { artist: 'Willie Nelson', title: 'Live At Budokan' },
            { artist: '49 Winchester', title: 'Fortune Favors The Bold' },
            { artist: 'Ian Noe', title: 'River Fools And Mountain Saints' },
            { artist: 'Michelle Rivers', title: 'Chasing Somewhere' },
            { artist: 'Dailey & Vincent', title: 'Let’s Sing Some Country!' },
            { artist: 'Gene Watson', title: 'Outside The Box' },
            { artist: 'Sunny Sweeney', title: 'Married Alone' },
            { artist: 'Charley Crockett', title: 'The Man From Waco' },
            { artist: 'Zachariah Malachi', title: 'Local Bar Opry Star' },
            { artist: 'Jake Penrod', title: 'Million-Dollar Cowboy' },
            { artist: 'Drake Milligan', title: 'Dallas/Fort Worth' },
            { artist: 'Billy Strings', title: 'Me / And / Dad' },
            { artist: 'Wesley Hanna', title: 'Brand New Love Potion' },
            { artist: 'Pug Johnson & The Hounds', title: 'Throwed Off And Glad' },
            { artist: 'Tami Neilson', title: 'Kingmaker' },
            { artist: 'Emily Nenni', title: 'On The Ranch' },
            { artist: 'Julie Roberts', title: 'Ain’t In No Hurry' },
        ],
    },
    {
        year: 2022,
        playlist: [
            { artist: 'American Aquarium', title: 'Chicamacomico' },
            { artist: 'Brennen Leigh (feat. Asleep At The Wheel)', title: 'Obsessed With The West' },
            { artist: 'Melissa Carper', title: 'Ramblin‘ Soul' },
            { artist: 'Eliza Gilkyson', title: 'Songs From The River Wind' },
            { artist: 'Willie Nelson', title: 'A Beautiful Time' },
            { artist: 'Willie Nelson', title: 'Live At Budokan' },
            { artist: 'Ian Noe', title: 'River Fools & Mountain Saints' },
            { artist: 'Bonnie Raitt', title: 'Just Like That' },
            { artist: 'Michelle Rivers', title: 'Chasing Somewhere' },
            { artist: 'Amanda Shires', title: 'Take It Like A Man' },
            { artist: 'Sunny Sweeney', title: 'Married Alone' },
            { artist: 'Molly Tuttle & Golden Highway', title: 'Crooked Tree' },
        ],
    },
    {
        year: 2022,
        month: 12,
        image: '/images/AmericanAquarium_Chicamacomico.png',
        playlist: [
            { artist: 'American Aquarium', title: 'Chicamacomico' },
            { artist: '	Sunny Sweeney', title: 'Married Alone' },
            { artist: '	Melissa Carper', title: 'Ramblin‘ Soul' },
            { artist: '	Michelle Rivers', title: 'Chasing Somewhere' },
            { artist: '	49 Winchester', title: 'Fortune Favors The Bold' },
            { artist: '	Willie Nelson', title: 'Live At Budokan' },
            { artist: '	Dailey & Vincent', title: 'Let’s Sing Some Country!' },
            { artist: '	Gene Watson', title: 'Outside The Box' },
            { artist: '	Amanda Shires', title: 'Take It Like A Man' },
            { artist: '	Tami Neilson', title: 'Kingmaker' },
            { artist: '	Thomas Michael Riley', title: 'Texas Six Pack' },
            { artist: '	Drake Milligan', title: 'Dallas/Fort Worth' },
            { artist: '	Ashley McBryde presents', title: 'Lindeville' },
            { artist: '	Charley Crockett', title: 'The Man From Waco' },
            { artist: '	Jon Pardi', title: 'Mr. Saturday Night' },
            { artist: '	Pug Johnson & The Hounds', title: 'Throwed Off And Glad' },
            { artist: '	Mary Gauthier', title: 'Dark Enough To See The Stars' },
            { artist: '	Billy Strings', title: 'Me / And / Dad' },
            { artist: '	Wesley Hanna', title: 'Brand New Love Potion' },
            { artist: '	Emily Nenni', title: 'On The Ranch' },
        ],
    },
    {
        year: 2022,
        month: 11,
        image: '/images/SunnySweeney-MarriedAlone.jpg',
        playlist: [
            { artist: 'Sunny Sweeney', title: 'Married Alone' },
            { artist: 'American Aquarium', title: 'Chicamacomico' },
            { artist: 'Michelle Rivers', title: 'Chasing Somewhere' },
            { artist: 'Ashley McBryde presents', title: 'Lindeville' },
            { artist: 'Gretchen Peters', title: 'The Show – Live From The UK' },
            { artist: 'Thomas Michael Riley', title: 'Texas Six Pack' },
            { artist: '49 Winchester', title: 'Fortune Favors The Bold' },
            { artist: 'Dailey & Vincent', title: 'Let’s Sing Some Country!' },
            { artist: 'Jim Lauderdale', title: 'Game Changer' },
            { artist: 'Gene Watson', title: 'Outside The Box' },
            { artist: 'Tami Neilson', title: 'Kingmaker' },
            { artist: 'Brennen Leigh (feat. Asleep At The Wheel)', title: '	Obsessed With The West' },
            { artist: 'Mary Gauthier', title: 'Dark Enough To See The Stars' },
            { artist: 'Drake Milligan', title: 'Dallas/Fort Worth' },
            { artist: 'Charley Crockett', title: 'The Man From Waco' },
            { artist: 'Bonnie Raitt', title: 'Just Like That' },
            { artist: 'Jon Pardi', title: 'Mr. Saturday Night' },
            { artist: 'Pug Johnson & The Hounds', title: 'Throwed Off And Glad' },
            { artist: 'Molly Tuttle & Golden Highway', title: 'Crooked Tree' },
            { artist: 'Adam Hood', title: 'Bad Days Better' },
        ],
    },
    {
        year: 2022,
        month: 10,
        image: '/images/ThomasMichaelRiley-TexasSixPack.jpg',
        playlist: [
            { artist: 'Thomas Michael Riley', title: 'Texas Six Pack' },
            { artist: 'Michelle Rivers', title: 'Chasing Somewhere' },
            { artist: 'Sunny Sweeney', title: 'Married Alone' },
            { artist: 'Ashley McBryde presents', title: 'Lindeville' },
            { artist: 'Gretchen Peters', title: 'The Show - Live From The UK' },
            { artist: 'Jim Lauderdale', title: 'Game Changer' },
            { artist: 'Tami Neilson', title: 'Kingmaker' },
            { artist: 'American Aquarium', title: 'Chicamacomico' },
            { artist: 'Mary Gauthier', title: 'Dark Enough To See The Stars' },
            { artist: '49 Winchester', title: 'Fortune Favors The Bold' },
            { artist: 'Brennen Leigh (feat. Asleep At The Wheel)', title: 'Obsessed With The West' },
            { artist: 'Dailey & Vincent', title: 'Let’s Sing Some Country!' },
            { artist: 'Bonnie Raitt', title: 'Just Like That' },
            { artist: 'Gene Watson', title: 'Outside The Box' },
            { artist: 'Molly Tuttle & Golden Highway', title: 'Crooked Tree' },
            { artist: 'Willie Nelson', title: 'A Beautiful Time' },
            { artist: 'Eliza Gilkyson', title: 'Songs From The River Wind' },
            { artist: 'Jon Pardi', title: 'Mr. Saturday Night' },
            { artist: 'Wade Bowen', title: 'Somewhere Between The Secret And The Truth' },
            { artist: 'Amanda Shires', title: 'Take It Like A Man' },
        ],
    },
    {
        year: 2022,
        month: 9,
        image: '/images/TamiNeilson-KingMaker.jpg',
        playlist: [
            { artist: 'Tami Neilson', title: 'Kingmaker' },
            { artist: 'Michelle Rivers', title: 'Chasing Somewhere' },
            { artist: 'Mary Gauthier', title: 'Dark Enough To See The Stars' },
            { artist: 'Jim Lauderdale', title: 'Game Changer' },
            { artist: 'Gretchen Peters', title: 'The Show – Live From The UK' },
            { artist: 'Willie Nelson', title: 'A Beautiful Time' },
            { artist: 'Bonnie Raitt', title: 'Just Like That' },
            { artist: 'Brennen Leigh (feat. Asleep At The Wheel)', title: 'Obsessed With The West' },
            { artist: 'Molly Tuttle & Golden Highway', title: 'Crooked Tree' },
            { artist: 'Sierra Ferrell', title: 'Long Time Coming' },
            { artist: 'Eliza Gilkyson', title: 'Songs From The River Wind' },
            { artist: 'Various Artists	', title: 'A Tribute To John Anderson' },
            { artist: 'Wade Bowen', title: 'Somewhere Between The Secret And The Truth' },
            { artist: 'Sunny Sweeney', title: 'Married Alone' },
            { artist: 'Hailey Whitters', title: 'Raised' },
            { artist: 'Hank Williams Jr.', title: 'Rich White Honky Blues' },
            { artist: 'American Aquarium', title: 'Chicamacomico' },
            { artist: 'Allison Russell', title: 'Outside Child' },
            { artist: 'Amanda Shires', title: 'Take It Like A Man' },
            { artist: 'Kelsey Waldon', title: 'No Regular Dog' },
        ],
    },
    {
        year: 2022,
        month: 8,
        image: '/images/BonnieRaitt-JustLikeThat.jpg',
        playlist: [
            { artist: 'Bonnie Raitt', title: 'Just Like That' },
            { artist: 'Willie Nelson', title: 'A Beautiful Time' },
            { artist: 'Mary Gauthier', title: 'Dark Enough To See The Stars' },
            { artist: 'Tami Neilson', title: 'Kingmaker' },
            { artist: 'Brennen Leigh (feat. Asleep At The Wheel)', title: 'Obsessed With The West' },
            { artist: 'Molly Tuttle & Golden Highway', title: 'Crooked Tree' },
            { artist: 'Sierra Ferrell', title: 'Long Time Coming' },
            { artist: 'Eliza Gilkyson', title: 'Songs From The River Wind' },
            { artist: 'Hailey Whitters', title: 'Raised' },
            { artist: 'Michelle Rivers	', title: 'Chasing Somewhere' },
            { artist: 'Hank Williams Jr.', title: 'Rich White Honky Blues' },
            { artist: 'Joshua Hedley', title: 'Neon Blue' },
            { artist: 'Paul Cauthen', title: 'Country Coming Down' },
            { artist: 'Pokey LaFarge', title: 'In The Blossom Of Their Shade' },
            { artist: 'Various Artists', title: 'A Tribute To John Anderson' },
            { artist: 'Allison Russell', title: 'Outside Child' },
            { artist: 'American Aquarium', title: 'Chicamacomico' },
            { artist: 'Charley Crockett', title: 'Lil‘ G.L. Presents: Jukebox Charley' },
            { artist: 'Peter Rowan', title: 'Calling You From My Mountain' },
            { artist: 'Vandoliers', title: 'Vandoliers' },
        ],
    },
    {
        year: 2022,
        month: 7,
        image: '/images/SierraFerrell_LongTimeComing.jpg',
        playlist: [
            { artist: 'Sierra Ferrell', title: 'Long Time Coming' },
            { artist: 'Brennen Leigh (feat. Asleep At The Wheel)', title: 'Obsessed With The West' },
            { artist: 'Willie Nelson', title: 'A Beautiful Time' },
            { artist: 'Bonnie Raitt', title: 'Just Like That' },
            { artist: 'Molly Tuttle & Golden Highway', title: 'Crooked Tree' },
            { artist: 'Eliza Gilkyson', title: 'Songs From The River Wind' },
            { artist: 'Hailey Whitters	', title: 'Raised' },
            { artist: 'Joshua Hedley', title: 'Neon Blue' },
            { artist: 'Mary Gauthier', title: 'Dark Enough To See The Stars' },
            { artist: 'Paul Cauthen', title: 'Country Coming Down' },
            { artist: 'Pokey LaFarge', title: 'In The Blossom Of Their Shade' },
            { artist: 'Charley Crockett', title: 'Lil‘ G.L. Presents: Jukebox Charley' },
            { artist: 'Allison Russell', title: 'Outside Child' },
            { artist: 'Dale Watson	', title: 'Jukebox Fury' },
            { artist: 'Lyle Lovett', title: '12th Of June' },
            { artist: 'Margo Cilker', title: 'Pohorylle' },
            { artist: 'Krüger Brothers & Keiser Twins', title: 'Carolina Roots' },
            { artist: 'Billy Strings', title: 'Renewal' },
            { artist: 'Jon Wolfe', title: 'Dos Corazones' },
            { artist: 'Hank Williams Jr.', title: 'Rich White Honky Blues' },
        ],
    },
    {
        year: 2022,
        month: 6,
        image: '/images/BrennenLeigh_ObsessedWithTeWest.jpg',
        playlist: [
            { artist: 'Brennen Leigh (feat. Asleep At The Wheel)', title: 'Obsessed With The West' },
            { artist: 'Molly Tuttle & Golden Highway', title: 'Crooked Tree' },
            { artist: 'Eliza Gilkyson', title: 'Songs From The River Wind' },
            { artist: 'Willie Nelson', title: 'A Beautiful Time' },
            { artist: 'Hailey Whitters', title: 'Raised' },
            { artist: 'Joshua Hedley', title: 'Neon Blue' },
            { artist: 'Sierra Ferrell', title: 'Long Time Coming' },
            { artist: 'Bonnie Raitt', title: 'Just Like That' },
            { artist: 'Pokey LaFarge', title: 'In The Blossom Of Their Shade' },
            { artist: 'Charley Crockett', title: 'Lil‘ G.L. Presents: Jukebox Charley' },
            { artist: 'Paul Cauthen', title: 'Country Coming Down' },
            { artist: 'Allison Russell', title: 'Outside Child' },
            { artist: 'Dale Watson', title: 'Jukebox Fury' },
            { artist: 'Margo Cilker', title: 'Pohorylle' },
            { artist: 'Krüger Brothers & Keiser Twins', title: 'Carolina Roots' },
            { artist: 'Jon Wolfe', title: 'Dos Corazones' },
            { artist: 'Billy Strings', title: 'Renewal' },
            { artist: 'Punch Brothers', title: 'Hell On Church Street' },
            { artist: 'Lyle Lovett', title: 'Crooked Tree' },
            { artist: 'Christina Vane', title: 'Make Myself Me Again' },
        ],
    },
    {
        year: 2022,
        month: 5,
        image: '/images/MollyTuttle&GoldenHighway_CrookedTree.jpg',
        playlist: [
            { artist: 'Molly Tuttle & Golden Highway', title: 'Crooked Tree' },
            { artist: 'Eliza Gilkyson', title: 'Songs From The River Wind' },
            { artist: 'Brennen Leigh (feat. Asleep At The Wheel)', title: 'Obsessed With The West' },
            { artist: 'Sierra Ferrell', title: 'Long Time Coming' },
            { artist: 'Margo Cilker', title: 'Pohorylle' },
            { artist: 'Hailey Whitters', title: 'Raised' },
            { artist: 'Joshua Hedley', title: 'Neon Blue' },
            { artist: 'Pokey LaFarge', title: 'In The Blossom Of Their Shade' },
            { artist: 'Willie Nelson', title: 'A Beautiful Time' },
            { artist: 'Jon Wolfe', title: 'Dos Corazones' },
            { artist: 'Krüger Brothers & Keiser Twins', title: 'Carolina Roots' },
            { artist: 'Allison Russell', title: 'Outside Child' },
            { artist: 'Charley Crockett', title: 'Lil‘ G.L. Presents: Jukebox Charley' },
            { artist: 'Billy Strings', title: 'Renewal' },
            { artist: 'Paul Cauthen', title: 'Country Coming Down' },
            { artist: 'Punch Brothers', title: 'Hell On Church Street' },
            { artist: 'Katie Jo', title: 'Pawn Shop Queen' },
            { artist: 'Erin Rae', title: 'Lighten Up' },
            { artist: 'Mike and the Moonpies', title: 'One To Grow On' },
            { artist: 'Brandi Carlile', title: 'In These Silent Days' },
        ],
    },
    {
        year: 2022,
        month: 4,
        image: '/images/Eliza_Gilkyson_Songs_From_The_River_Wind.jpg',
        playlist: [
            { artist: 'Eliza Gilkyson', title: 'Songs From The River Wind' },
            { artist: 'Sierra Ferrell', title: 'Long Time Coming' },
            { artist: 'Jon Wolfe', title: 'Dos Corazones' },
            { artist: 'Pokey LaFarge', title: 'In The Blossom Of Their Shade' },
            { artist: 'Krüger Brothers & Keiser Twins', title: 'Carolina Roots' },
            { artist: 'Margo Cilker', title: 'Pohorylle' },
            { artist: 'Billy Mata & The Texas Tradition', title: 'Cowboys, Crooners & Troubadours' },
            { artist: 'Molly Tuttle & Golden Highway', title: 'Crooked Tree' },
            { artist: 'Billy Strings', title: 'Renewal' },
            { artist: 'Mike and the Moonpies', title: 'One To Grow On' },
            { artist: 'Hailey Whitters', title: 'Raised' },
            { artist: 'Joshua Hedley', title: 'Neon Blue' },
            { artist: 'Punch Brothers', title: 'Hell On Church Street' },
            { artist: 'Charles Wesley Godwin', title: 'How The Mighty Fall' },
            { artist: 'Brandi Carlile', title: 'In These Silent Days' },
            { artist: 'Allison Russell', title: 'Outside Child' },
            { artist: 'Jackson Browne', title: 'Downhill From Everywhere' },
            { artist: 'Katie Jo', title: 'Pawn Shop Queen' },
            { artist: 'Erin Rae', title: 'Lighten Up' },
            { artist: 'Wilder Blue', title: 'Wilder Blue' },
        ],
    },
];

export const getThreeNewestPlaylists = () => {
    return playlists
        .sort((p1, p2) => p1.year * 13 + (p1.month ?? 13) - (p2.year * 13 + (p2.month ?? 13)))
        .reverse()
        .slice(0, 3);
};

export const getNewestPlaylist = () => {
    return playlists.sort((p1, p2) => p1.year * 13 + (p1.month ?? 13) - (p2.year * 13 + (p2.month ?? 13))).reverse()[0];
};
